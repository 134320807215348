import React from 'react'
import styled, { css } from 'styled-components'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import siteConfig from '../../data/siteConfig'
import { withPrefix } from "gatsby"
import loadable from '@loadable/component'
import Hero from '../components/hero'
import SEO from '../components/SEO'
import Wrapper from '../components/wrapper'

const Layout = loadable(() => import('../components/layout'))

const Image = styled.img`
  align: center;
  max-height: 220px;
  max-width: 220px;
  object-fit: cover;
  object-position: center center;
  border-radius: 10px;
  box-shadow: 12px 24px 40px -11px rgba(0,0,0,0.51);
`

const JobCard = styled.div`
  text-decoration: none;
  color: inherit;

  ${({ href }) => href && css`
    &:hover ${Image}{
      transition: transform .5s;
      transform: translateY(-5px);
    }
  `}
`

const CardTitle = styled.a`
  text-decoration: underline;
  color: inherit;
  
  &:hover {
    color: #A9A9A9;    
  }
`

const Portifolio = ({ className, location }) => {
  const title = "Portifolio"
  const { keywords, portifolio } = siteConfig
  return (
    <Layout location={location}>
      <SEO
        title={title}
        keywords={keywords}
      />

      <Hero
        heroImg={withPrefix('/images/pierre-chatel-innocenti-W5INoOK-5eI-unsplash.jpeg')}
        title={title}
      />

      <Wrapper className={className}>
        <Container className="page-content" fluid>
          <Row>
            {portifolio.map(job => (
              <Col key={job.name} align="center">
                <JobCard as={"div"}>
                  <Row>
                    <h2>
                      <CardTitle href={job.url} target="_blank">
                        {job.name}
                      </CardTitle>
                    </h2>
                  </Row>
                  <Row>
                    <Col style={{flex: 0.2}}>
                      <a href={job.url} target="_blank">
                        <Image src={withPrefix(job.image)} align="center"/>
                      </a>
                    </Col>
                    <Col style={{flex: 0.8}}>
                      <p>{job.description}</p>
                    </Col>
                  </Row>
                  <br></br>
                </JobCard>
              </Col>
            ))}
          </Row>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default styled(Portifolio)`
  .page-content {
    max-width: 100%;
    margin-bottom: 40px;
  }

`
